import { trpc } from "@access/trpc/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

import type { AppProps } from "@lib/app-providers";

import "../styles/globals.css";

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
  }
}

function MyApp(props: AppProps) {
  const { Component, pageProps } = props;

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (typeof window.fbq !== "undefined") {
        window.fbq("track", "PageView");
      }
    };

    if (!window.fbq) {
      (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      (window as any).fbq("init", "1018945676060044");
    }

    // Track initial page load
    window.fbq("track", "PageView");

    // Track page view on route change
    router.events.on("routeChangeComplete", handleRouteChange);

    // Clean up on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (Component.PageWrapper !== undefined) return Component.PageWrapper(props);
  return (
    <>
      <Head>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=1018945676060044&ev=PageView&noscript=1"
            alt="facebook-pixel"
          />
        </noscript>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default trpc.withTRPC(MyApp);
